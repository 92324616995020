import React from "react";

import Goo from "../images/goo.jpg"
import Apstore from "../images/apestra.png"

export default function Comming() {
    return (
        
          
                    <div className="comingdiv col-md-4 col-lg-4 Coming ">
                        <h3 >  COMING SOON</h3>
                        <ul>
                            <img style={{width:"100%", }} src={Goo} alt="" />
                            <img style={{width:"100%", }} src={Apstore} alt="" />
                     
                        </ul>
                
            </div>
     
    )
}

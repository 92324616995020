import RoadmapAnimation from "../components/RoadmapAnimation";
import RoadmapAnimationXL from "../components/RoadmapAnimationXL"
import RoadmapAnimationXS from "../components/RoadmapAnimationXS";
import "../styles/roadmap.css"


export default function Roadmap() {
    
    return (
        <section className='roadmap' id="roadMap">
             <div className='container'>
                <div className='roadtitle'>
                    <h1>ROAD MAP</h1>
                  
                </div>
                <RoadmapAnimationXS/>
                <RoadmapAnimation/>
                <RoadmapAnimationXL/>
                
                <div className='container roadmaplist'>
                </div>
            </div> 


        </section>
    );
}


import ruleta from "../images/ruletasupply.png"
import pieza1 from "../images/Hamburguesa por partes/1dex.png"
import pieza2 from "../images/Hamburguesa por partes/2Advisors.png"
import pieza3 from "../images/Hamburguesa por partes/3Private.png"
import pieza4 from "../images/Hamburguesa por partes/4liquidaty.png"
import pieza5 from "../images/Hamburguesa por partes/5Rewards.png"
import pieza6 from "../images/Hamburguesa por partes/6TeamTokens.png"
import pieza7 from "../images/Hamburguesa por partes/7Private.png"
import pieza8 from "../images/Hamburguesa por partes/8devtoken.png"
import pieza9 from "../images/Hamburguesa por partes/9Marketing.png"
import pieza10 from "../images/Hamburguesa por partes/10Tglisting.png"
import token from "../images/Moneda.png"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import "../styles/tokenomic.css"

export function Tokenomics() {
  return (
    <section className="tokenomic" id="tokenomics">
      <div className="container">
        <h1>Tokenomics</h1>
        <div className="row justify-content-center">
          <div className="col-md-6 tokeburger">
            <div className="row justify-content-center parts">

              <div id="part1" className="col-12 d-flex  justify-content-center part">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="tlp" id="tooltip1">
                     15% TGE  and  Linear Release for 10 months
                    </Tooltip>}>
                  <img src={pieza1} alt={pieza1} />
                </OverlayTrigger>
              </div>

              <div id="part2" className="col-12 d-flex  justify-content-center part">
              <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="tlp" id="tooltip1">
                      5% TGE and 20 Weeks Linear Vesting
                    </Tooltip>}>
              <img src={pieza2} alt={pieza2} />
              </OverlayTrigger>
              </div>


              <div id="part3" className="col-12 d-flex  justify-content-center part">
              <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="tlp" id="tooltip1">
                      15% TGE and  Linear Release for 10 months
                    </Tooltip>}>
              <img src={pieza3} alt={pieza3} />
              </OverlayTrigger>
              </div>
              

              <div id="part4" className="col-12 d-flex  justify-content-center part">
              <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="tlp" id="tooltip1">
                      0% TGE and Locked
                    </Tooltip>}>
              <img src={pieza4} alt={pieza4} />
              </OverlayTrigger>
              </div>

              <div id="part5" className="col-12 d-flex  justify-content-center part">
              <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="tlp" id="tooltip1">
                      0% TGE and Release According Player base
                    </Tooltip>}>
              <img src={pieza5} alt={pieza5} />
              </OverlayTrigger>
              </div>

              <div id="part6" className="col-12 d-flex  justify-content-center part">
              <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="tlp" id="tooltip1">
                      0% and 20 months Linear Vesting
                    </Tooltip>}>
              <img src={pieza6} alt={pieza6} />
              </OverlayTrigger>
              </div>
              <div id="part7" className="col-12 d-flex  justify-content-center part">
              <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="tlp" id="tooltip1">
                      10% TGE and  Linear Release for 20 months
                    </Tooltip>}>
              <img src={pieza7} alt={pieza7} />
              </OverlayTrigger>


              </div>
              <div id="part8" className="col-12 d-flex  justify-content-center part">
              <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="tlp" id="tooltip1">
                      5% TGE and 20 Weeks Linear Vesting
                    </Tooltip>}>
              <img src={pieza8} alt={pieza8} />
              </OverlayTrigger>


              </div>
              <div id="part9" className="col-12 d-flex  justify-content-center part">
              <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="tlp" id="tooltip1">
                       5% TGE and 20 Weeks Linear Vesting
                    </Tooltip>}>
              <img src={pieza9} alt={pieza9} />
              </OverlayTrigger>


              </div>
              <div id="part10" className="col-12 d-flex  justify-content-center part">
              <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="tlp" id="tooltip1">
                      100% TGE and  No Vesting 
                    </Tooltip>}>
              <img src={pieza10} alt={pieza10} />
              </OverlayTrigger>


              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex  justify-content-center">
            <img src={ruleta} className="ruleta" alt="" />
          </div>
        </div>
      </div>
      <img className="token" id="token0" src={token} alt="token" />
      <img className="token" id="token1" src={token} alt="token" />
      <img className="token" id="token2" src={token} alt="token" />
      <img className="token" id="token3" src={token} alt="token" />
      <img className="token" id="token4" src={token} alt="token" />
  

      <img className="token" id="token6" src={token} alt="token" />
      <img className="token" id="token7" src={token} alt="token" />
      <img className="token" id="token8" src={token} alt="token" />
      <img className="token" id="token9" src={token} alt="token" />
      <img className="token" id="token10" src={token} alt="token" />
 
    </section>
  )
}

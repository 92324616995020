
import navbar from "../images/Navbar.png"
import "../styles/navbar.css"
export function Navbar() {


    return (
        <nav className="navbar navbar-expand-lg navbar-dark ">
            <div className="container-fluid">
                <img className="navbar-brand" src={navbar}/>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto me-auto">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="#banner">HOME</a>
                        </li>
                        <li className="nav-item">
                            <a href="#aboutUs" className="nav-link">ABOUT US</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#roadMap">ROADMAP</a>
                        </li>
                        <li className="nav-item" >

                            <a href="#Team" className="nav-link ">TEAM</a>
                        </li>
                        <li className="nav-item" >
                            <a href="#tokenomics" className="nav-link ">TOKENOMICS</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" rel="noreferrer" href="https://cojoa-fiorio.gitbook.io/cryptomc-official/" target="_blank">WHITEPAPER</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}


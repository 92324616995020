import { Popover, OverlayTrigger } from "react-bootstrap";

export const Q42021 =()=>{
    return (
                <ul>
                    <li>Team formation.</li>
                    <li>Development of game logic.</li>
                    <li>Marketing Proposal.</li>
                </ul>
    )
}

export const Q12022 =()=>{
    return(
    <ul>
        <li>Launch of the website.</li>
        <li>Launch on social networks.</li>
        <li>Marketing: Phase 1.</li>
    </ul>
    )
}

export const Q22022 =()=>{
    return(
        <ul>
        <li>Video Trailer.</li>
        <li>Presale NFTS.</li>
        <li>Token audit.</li>
        <li>Private token sale round.</li>
        <li>Token sale community round.</li>
        <li>Listed on PancakeSwap.</li>
        <li>Listed in Coingecko.</li>
        <li>Marketing: Phase 2.</li>
        <li>Launching of the Marketplace.</li>
        <li>Game launch.</li>
        </ul>
    )
}

export const Q32022 =()=>{
    return(
        <ul>
        <li>In-game advertising application.</li>
        <li>Global Marketing: Phase 3.</li>
        <li>Stake System.</li>
        <li>PVP system of PERMANENT NFTs.</li>
        <li>PVP system of TEMPORARY NFTs.</li>
        <li>Franchises (Lands).</li>
        </ul>
    )
}

export const Q42022 =()=>{
    return(
        <ul>
        <li>scholarship system. </li>
        <li>Minigames. </li>
        <li>multiverse. </li>
        </ul>
    )
}

export const Q12023 =()=>{
    return(
        <ul>
        <li>NFT connection and earnings from different games within the multiverse. </li>
    </ul>
    )
}
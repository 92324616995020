import ReactPlayer from 'react-player/youtube';
import "../styles/trailer.css";
export default function Trailer() {
    return (
        <section className="trailer">
            <div className="container">
                <h1>Game Trailer</h1>
            <div className='video'>
            <ReactPlayer 
            url='https://www.youtube.com/watch?v=_rZz4S68Itg'
            width='100%'
            height='100%'
             />
            </div>
            </div>
        </section>
    )
}

import "../styles/about.css"

export function AboutUs() {

  return (
    <section className='aboutus' id="aboutUs">
      <div className='container-fluid '>
        <h1>ABOUT US</h1>
        <p>
          
          We are professionals who love NFT games, current technologies such as BlockChain in this case, and we came to stay with a solid, simple and professional project. We have previous experiences from the biggest and best NFT games and different solutions to keep our game on top over time along with full trust in the community. Always on the side of the players, because that's where we come from and we want the best for them. The more we help you to be successful, the more successful we are.

        </p>
      </div>
    </section>
  )
}

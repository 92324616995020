
import Slider2 from "../components/Sliderv2";
import "../styles/burger.css"
import burger from "../images/Common.png";
import burger2 from "../images/Uncommon.png";
import burger3 from "../images/Rare.png";
import burger4 from "../images/Legendary.png";
import fila from "../images/fila.png"
import { Parallax } from "react-parallax";
import Cheese from "../images/queso2.png"

export function Burger() {


    return (

        <section className="Burger" id="burger">
            <img src={fila} className="cajitafila  rounded mx-auto d-block" alt="" />
        <Parallax className="cheese" bgImage={Cheese} strength={700}>
         
         
       
        <div className="Burger-Title">
            <h1>NFT COLLECTION</h1>
        </div>
            <Slider2/>
        <div className="movilBurger">
            <img src={burger} alt={burger} />
            <h3>Classic</h3>
            <h1>Burger</h1>
            <img src={burger2} alt={burger} />
            <h3>Triple cheese</h3>
            <h1>Burger</h1>
            <img src={burger3} alt={burger} />
            <h3>MegaMc</h3>
            <h1>Burger</h1>
            <img src={burger4} alt={burger} />
            <h3>Home Made</h3>
            <h1>Burger</h1>
        </div>
        
        </Parallax>
        </section>
        
    )
}
import patro1 from "../images/patro1.png";
import patro2 from "../images/patro2.png";
import "../styles/partners.css"

export default function Partners() {
    return (
        <section className="partners">
            <h1>Partners</h1>
            <div className="row partnersImg">
                <div className="col-md-6">
                    <img src={patro1} alt="" className="rounded" />
                </div>
                <div className="col-md-6">
                    <img src={patro2} alt="" className="rounded" />
                </div>

            </div>
        </section>
    )
}

import React from "react";

export default function About() {
    return (


        <div style={{ margin: "50px 0px 0px 0px" }} className="col-md-4 col-lg-4 About ">
            <h3 >  SOCIAL NETWORKS <br></br> AND SUPPORT</h3>

            <div>

                <a href="https://t.me/+7qaDYwYdLlUwZTdh" target="_blank" rel="noreferrer"><i className="fab fa-telegram-plane"></i></a>
                <a href="https://twitter.com/CryptoMcOficial" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>
                <a href="https://discord.com/invite/QUYa4a2FHD" target="_blank" rel="noreferrer"><i className="fab fa-discord"></i></a>
                <a href="" target="_blank" rel="noreferrer"><i className="fab fa-medium-m"></i></a>
                <a href="https://www.instagram.com/cryptomcofficial/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                <a href = "mailto:CryptoMcSoporte@hotmail.com"><i className="fas fa-envelope"></i></a>
            </div>

        

        </div>

    )
}
